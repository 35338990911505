import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/ruoyi'

// 查询调用开放接口的客户端信息列表
export function listClient (query) {
  return request({
    url: '/openapi/client/list',
    method: 'get',
    params: query
  })
}

// 查询调用开放接口的客户端信息详细
export function getClient (ID) {
  return request({
    url: '/openapi/client/' + praseStrEmpty(ID),
    method: 'get'
  })
}

// 查询调用开放接口的客户端信息详细
export function getClientByTenantId (tenantId) {
  return request({
    url: '/openapi/client/tenant/' + tenantId,
    method: 'get'
  })
}

// 查询我(租户本人)的客户端信息
export function getMyClientInfo () {
  return request({
    url: '/openapi/client/myClientInfo',
    method: 'get'
  })
}

// 新增调用开放接口的客户端信息
export function addClient (data) {
  return request({
    url: '/openapi/client',
    method: 'post',
    data: data
  })
}

// 修改调用开放接口的客户端信息
export function updateClient (data) {
  return request({
    url: '/openapi/client',
    method: 'put',
    data: data
  })
}

// 重置开放接口的客户端secret
export function resetClientSecret (ID) {
  return request({
    url: '/openapi/client/generateSecret/' + praseStrEmpty(ID),
    method: 'post'
  })
}

// 重置开放接口的客户端ApiSecret
export function resetApiSecret (ID) {
  return request({
    url: '/openapi/client/generateApiSecret/' + praseStrEmpty(ID),
    method: 'post'
  })
}

// 修改ip白名单
export function updateIPList (data) {
  return request({
    url: '/openapi/client/ipWhitelist',
    method: 'put',
    data: data
  })
}

// 删除调用开放接口的客户端信息
export function delClient (ID) {
  return request({
    url: '/openapi/client/' + ID,
    method: 'delete'
  })
}

// 导出调用开放接口的客户端信息
export function exportClient (query) {
  return request({
    url: '/openapi/client/export',
    method: 'get',
    params: query
  })
}
