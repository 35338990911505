/*
 * @Auth: linjituan
 * @Date: 2022-09-21 15:50:05
 * @LastEditors: linjituan
 * @LastEditTime: 2022-09-21 18:12:54
 */
import request from '@/utils/request'

// 查询Client资源配置列表
export function listClientResource(query) {
  return request({
    url: '/openapi/clientResource/list',
    method: 'get',
    params: query
  })
}

// 查询Client资源配置详细
export function getClientResource(id) {
  return request({
    url: '/openapi/clientResource/' + id,
    method: 'get'
  })
}

// 新增Client资源配置
export function addClientResource(data) {
  return request({
    url: '/openapi/clientResource',
    method: 'post',
    data: data
  })
}

// 修改Client资源配置
export function updateClientResource(data) {
  return request({
    url: '/openapi/clientResource',
    method: 'put',
    data: data
  })
}

// 删除Client资源配置
export function delClientResource(id) {
  return request({
    url: '/openapi/clientResource/' + id,
    method: 'delete'
  })
}

// 导出Client资源配置
export function exportClientResource(query) {
  return request({
    url: '/openapi/clientResource/export',
    method: 'get',
    params: query
  })
}

// 获取未曾配置的资源列表
export function canUserResourceList(id) {
  return request({
    url: '/openapi/resource/getList',
    method: 'get'
  })
}
