var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        zIndex: 3000,
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "所属租户", prop: "tenantId" } },
            [_c("div", [_vm._v(_vm._s(_vm.tenantName))])]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "资源", prop: "resourceId" } },
            [
              _vm.formType == 1
                ? _c(
                    "a-select",
                    {
                      attrs: {
                        dropdownClassName: "openapi_RESOURCE_select",
                        placeholder: "请选择资源",
                        notFoundContent: "资源已全部配置",
                        "option-label-prop": "label",
                      },
                      on: { change: _vm.handleChangeSelect },
                      model: {
                        value: _vm.form.resourceId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "resourceId", $$v)
                        },
                        expression: "form.resourceId",
                      },
                    },
                    _vm._l(_vm.resourceList, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item,
                          attrs: { label: item.name, value: item.id },
                        },
                        [
                          _c("div", [_vm._v(_vm._s(item.name))]),
                          _c("div", [_vm._v(_vm._s(item.perms))]),
                        ]
                      )
                    }),
                    1
                  )
                : _c("div", [
                    _c("div", [_vm._v(_vm._s(_vm.form.name))]),
                    _c("div", [_vm._v(_vm._s(_vm.form.perms))]),
                  ]),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "Client Id", prop: "clientId" } },
            [_c("div", [_vm._v(_vm._s(_vm.form.clientId))])]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "每分钟访问api的次数", prop: "numberLimit" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入每分钟访问api的次数" },
                model: {
                  value: _vm.form.numberLimit,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "numberLimit", $$v)
                  },
                  expression: "form.numberLimit",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }