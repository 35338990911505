<template>
  <a-drawer
    width="35%"
    :zIndex="3000"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    :title="fromTitle"
    @close="onClose"
  >
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="所属租户" prop="tenantId">
        <!-- <a-select
          show-search
          v-model="form.tenantId"
          placeholder="请选择租户"
          option-filter-prop="children"
          :filter-option="filterOption"
        >
          <a-select-option v-for="(d, index) in tenantOptions" :key="index" :value="d.id">
            {{ d.name }}
          </a-select-option>
        </a-select> -->
        <div>{{ tenantName }}</div>
      </a-form-model-item>
      <!-- <a-form-model-item label="所属租户" prop="tenantId" v-if="formType == 2">
        <a-input type="text" disabled v-model="form.tenantName" />
      </a-form-model-item> -->
      <a-form-model-item label="资源" prop="resourceId">
        <a-select
          v-if="formType == 1"
          dropdownClassName="openapi_RESOURCE_select"
          v-model="form.resourceId"
          placeholder="请选择资源"
          notFoundContent="资源已全部配置"
          option-label-prop="label"
          @change="handleChangeSelect"
        >
          <a-select-option v-for="item in resourceList" :label="item.name" :value="item.id" :key="item">
            <div>{{ item.name }}</div>
            <div>{{ item.perms }}</div>
          </a-select-option>
        </a-select>
        <div v-else>
          <div>{{ form.name }}</div>
          <div>{{ form.perms }}</div>
        </div>
      </a-form-model-item>
      <a-form-model-item label="Client Id" prop="clientId">
        <div>{{ form.clientId }}</div>
        <!-- <a-input v-model="form.clientId" placeholder="请输入Client Id" /> -->
      </a-form-model-item>
      <a-form-model-item label="每分钟访问api的次数" prop="numberLimit">
        <a-input v-model="form.numberLimit" placeholder="请输入每分钟访问api的次数" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm"> 保存 </a-button>
          <a-button type="dashed" @click="cancel"> 取消 </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {
  getClientResource,
  addClientResource,
  updateClientResource,
  canUserResourceList
} from '@/api/openapi/clientResource'
import { getClient } from '@/api/openapi/client'

export default {
  name: 'CreateForm',
  props: {
    clientInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        resourceId: undefined,
        clientId: null,
        numberLimit: null,
        tenantId: undefined
      },
      tenantName: '',
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        tenantId: [{ required: true, message: '所属租户不能为空', trigger: 'blur' }],
        resourceId: [{ required: true, message: '资源不能为空', trigger: 'blur' }],
        clientId: [{ required: true, message: 'Client Id不能为空', trigger: 'blur' }],
        numberLimit: [{ required: true, message: '每分钟访问api的次数不能为空', trigger: 'blur' }]
      },
      resourceList: [],
      tenantOptions: []
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {
    open(val) {
      if (val) {
        this.canUserResourceList()
      }
    }
  },
  mounted() {},
  methods: {
    canUserResourceList() {
      this.loading = true
      canUserResourceList({}).then((response) => {
        this.resourceList = response.data
        if (this.form.resourceId) {
          let flag = false
          for (const item of this.resourceList) {
            if (item.id === this.form.resourceId) {
              flag = true
            }
          }
          if (!flag) {
            this.resourceList.unshift({
              id: this.form.resourceId,
              name: this.form.name
            })
          }
        }
        this.loading = false
      })
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        resourceId: undefined,
        clientId: null,
        numberLimit: null
      }
      this.form.clientId = this.clientInfo.clientId
      this.form.tenantId = this.clientInfo.tenantId
      this.tenantName = this.clientInfo.tenantName
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleChangeSelect(value) {
      this.form.numberLimit = this.resourceList.find(e => e.id === value).defaultCount
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      getClient().then((response) => {
        this.tenantOptions = response.tenants
        this.open = true
        this.formTitle = '新增Client信息'
      })
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      this.$refs.form && this.$refs.form.resetFields()
      getClientResource(id).then((response) => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        this.form.tenantName = row.tenantName
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateClientResource(this.form).then((response) => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addClientResource(this.form).then((response) => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less">
.openapi_RESOURCE_select {
  z-index: 3000;
}
</style>
